<app-breadcrumb></app-breadcrumb>
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<div class="table-container">
  <table aria-label="online-training">
    <thead>
      <tr id="main">
        <th class="document-column">DOCUMENT</th>
        <th class="date-column">DATE</th>
        <th class="size-column">FILE SIZE</th>
        <th class="action-column">ACTION</th>
      </tr>
    </thead>
    <tbody>
      <tr id="items" *ngFor="let document of documents">
        <td class="document-column">
          <div>
            <a (click)="downloadDocument(document.docid)">{{
              document.doctitletext
            }}</a>
          </div>
          <div><b>File:</b> {{ document.docname }}</div>
          <div><b>Category:</b> {{ document.category }}</div>
          <div><b>Description:</b> {{ document.descriptiontext }}</div>
        </td>
        <td class="date-column">
          {{ document?.updatetimestamp ?? document?.createtimestamp }}
        </td>
        <td class="size-column">{{ document.filesizetext }}</td>
        <td class="action-column">
          <button class="b2" (click)="editDocument(document.docid)">
            EDIT
          </button>
          <button
            class="b2"
            (click)="deleteDocument(document.docid, document.docname)"
          >
            DELETE
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <footer class="footer">
    <div class="button-container">
      <button class="b2" (click)="reorder()">RE-ORDER</button>
      <button class="b2 add" (click)="addDocument()">ADD</button>
    </div>
  </footer>
</div>
